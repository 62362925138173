import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  th: {
    translation: {
      button: {
        trigger: "trigger",
        back: "ย้อนกลับ",
        add: "เพิ่ม",
        delete: "ลบ",
        create: "สร้าง",
        export: "นำออกข้อมูล",
        import: "นำเข้าข้อมูล",
        download: "ดาวน์โหลด Template",
        submitFilter: "กรองข้อมูล",
        resetFilter: "ล้างตัวกรอง",
        callData: "เรียกข้อมูล",
        save: "บันทึก",
        saveDraft: "บันทึกร่าง",
        confirm: "ยืนยัน",
        cancel: "ยกเลิก",
        choose: "เลือก",
      },
      date: {
        range: "ช่วงเวลา",
        from: "ตั้งแต่",
        to: "จนถึง",
      },
      remark: "หมายเหตุ",
      report: "รายงาน",
      list: "รายการ",
      description: "รายละเอียด",
      documentInfo: "ข้อมูลเอกสาร",
      status: {
        index: "สถานะ",
        all: "ทั้งหมด",
        draft: "ร่าง",
        finished: "เสร็จสิ้น",
        cancelled: "ยกเลิก",
        waitAccept: "รอตอบรับ",
        accepted: "ตอบรับแล้ว",
        waitApprove: "รออนุมัติ",
        approved: "อนุมัติแล้ว",
        notApproved: "ไม่อนุมัติ",
        waitPayment: "รอชำระ",
        partiallyPaid: "ชำระแล้วบางส่วน",
        fullyPaid: "ชำระแล้ว",
        expired: "เกินเวลา",
        completed: "สำเร็จ",
        notCompleted: "ไม่สำเร็จ",
        active: "ใช้งาน",
        inActive: "หยุดใช้งาน",
        partiallyOrdered: "สั่งซื้อแล้วบางส่วน",
        fullyOrdered: "สั่งซื้อแล้ว",
        partiallyImported: "นำเข้าแล้วบางส่วน",
        fullyImported: "นำเข้าแล้ว",
        waitDeliver: "รอจัดส่ง",
        delivering: "กำลังจัดส่ง",
        waitPreviousJobs: "รองานอื่น",
        insufficientIngredient: "รอส่วนประกอบ",
        ready: "พร้อมผลิต",
        manufacturing: "กำลังผลิต",
        mainBom: "ใช้เป็นสูตรหลัก",
        pendingManu: "รอผลิต",
      },
      activityLog: {
        activityId: "เลขที่การเคลื่อนไหว",
        employeeIid: "เปลี่ยนแปลงเอกสารโดย",
        documentId: "เลขที่เอกสาร",
        payload: "ส่วนประกอบข้อมูล",
        createdDate: "วันที่สร้าง",
        documentType: "ประเภทเอกสาร",
        activityType: {
          index: "ประเภทการเคลื่อนไหว",
          comment: "คอมเมนต์",
          status_change: "เปลี่ยนสถานะ",
          edit: "แก้ไข",
          copy: "คัดลอก",
          cancel: "ยกเลิก",
          create: "สร้าง",
          related_document: "เอกสารที่เกี่ยวข้อง",
          relatedEmployee: "ผู้เกี่ยวข้อง",
          delete_related_employee: "ลบผู้เกี่ยวข้อง",
          add_related_employee: "เพิ่มผู้เกี่ยวข้อง",
          fact_change: "เปลี่ยนแปลงเอกสาร",
        },
      },
      sentence: {
        connection_error_title: "ไม่สามารถเชื่อมต่อกับอินเทอร์เน็ตได้",
        connection_error_message: "กรุณาเชื่อมต่อกับอินเทอร์เน็ต",
      },
      maintenance: "การดูแลระบบ",
      inventory: require("./inventory.json"),
      contact: require("./contact.json"),
      sales: require("./sales.json"),
      purchase: require("./purchase.json"),
      accounting: require("./account.json"),
      logistic: require("./logistic.json"),
      manufacture: require("./manufacture.json"),
      user: require("./user.json"),
      setting: require("./setting.json"),
      reports: require("./report.json"),
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "th",
  fallbackLng: "th",
  interpolation: {
    escapeValue: false,
  },
});
