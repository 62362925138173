import { gql } from "graphql-request";

export const CREATE_UOM = gql`
  mutation Mutation($createInput: UomCreateInput!) {
    uomCreate(createInput: $createInput) {
      id
      document_id
      name
    }
  }
`;

export const UPDATE_UOM = gql`
  mutation Mutation(
    $uniqueInput: UomWhereUniqueInput!
    $updateInput: UomUpdateInput!
  ) {
    uomUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      name
    }
  }
`;

export const DELETE_UOM = gql`
  mutation UomDelete($uniqueInput: UomWhereUniqueInput!) {
    uomDelete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_UOM_GROUP = gql`
  mutation Mutation($createInput: UomGroupCreateInput!) {
    uomGroupCreate(createInput: $createInput) {
      id
      name
      description
      document_id
      base_uom_document_id
      base_uom {
        id
        name
      }
      convertable_uom_list {
        id
        document_id
        name
      }
      uom_conversion_list {
        id
        base_uom_rate
        target_uom_document_id
        target_uom {
          id
          name
        }
        target_uom_rate
      }
    }
  }
`;

export const CREATE_UOM_GROUPS = gql`
  mutation UomGroupCreateMany($createManyInput: [UomGroupCreateInput!]!) {
    uomGroupCreateMany(createManyInput: $createManyInput) {
      results {
        id
        document_id
        name
        description
        base_uom_document_id
        base_uom {
          id
          document_id
          name
        }
        convertable_uom_list {
          id
          document_id
          name
        }
        uom_conversion_list {
          id
          uom_group_document_id
          base_uom_rate
          target_uom_document_id
          target_uom {
            id
            document_id
            name
          }
          target_uom_rate
        }
      }
      errors {
        index
        document_id
        errorMessages
      }
    }
  }
`;

export const UPDATE_UOM_GROUP = gql`
  mutation UomGroupUpdate(
    $updateInput: UomGroupUpdateInput!
    $uniqueInput: UomGroupWhereUniqueInput!
  ) {
    uomGroupUpdate(updateInput: $updateInput, uniqueInput: $uniqueInput) {
      id
      name
      description
      document_id
      base_uom_document_id
      base_uom {
        id
        name
      }
      convertable_uom_list {
        id
        document_id
        name
      }
      uom_conversion_list {
        id
        base_uom_rate
        target_uom_document_id
        target_uom {
          id
          name
        }
        target_uom_rate
      }
    }
  }
`;

export const CREATE_ATTRUBUTE = gql`
  mutation AttributeCreate($createInput: AttributeCreateInput!) {
    attributeCreate(createInput: $createInput) {
      id
      name
      acronym
      description
      data_type
    }
  }
`;

export const UPDATE_ATTRUBUTE = gql`
  mutation AttributeUpdate(
    $uniqueInput: AttributeWhereUniqueNameInput!
    $updateInput: AttributeUpdateInput!
  ) {
    attributeUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      name
      acronym
      description
      data_type
    }
  }
`;

export const DELETE_ATTRUBUTE = gql`
  mutation AttributeDelete($uniqueInput: AttributeWhereUniqueInput!) {
    attributeDelete(uniqueInput: $uniqueInput) {
      id
      name
      acronym
      data_type
      description
      created_date
    }
  }
`;

export const CREATE_WAREHOUSE = gql`
  mutation Mutation($createInput: WarehouseCreateInput!) {
    warehouseCreate(createInput: $createInput) {
      id
      document_id
      eng_name
      type
      thai_name
      description
      created_date
      is_active
    }
  }
`;

export const UPDATE_WAREHOUSE = gql`
  mutation Mutation(
    $uniqueInput: WarehouseWhereUniqueInput!
    $updateInput: WarehouseUpdateInput!
  ) {
    warehouseUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      type
      thai_name
      eng_name
      description
      created_date
      is_active
    }
  }
`;

export const DELETE_WAREHOUSE = gql`
  mutation Mutation($uniqueInput: WarehouseWhereUniqueInput!) {
    warehouseDelete(uniqueInput: $uniqueInput) {
      id
      document_id
      type
      thai_name
    }
  }
`;

export const CREATE_WAREHOUSE_LV1 = gql`
  mutation WarehouseSubLevel1Create(
    $createInput: WarehouseSubLevel1CreateInput!
  ) {
    warehouseSubLevel1Create(createInput: $createInput) {
      id
      document_id
      name
    }
  }
`;

export const UPDATE_WAREHOUSE_LV1 = gql`
  mutation WarehouseSubLevel1Update(
    $uniqueInput: WarehouseSubLevel1WhereUniqueInput!
    $updateInput: WarehouseSubLevel1UpdateInput!
  ) {
    warehouseSubLevel1Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      document_id
      name
    }
  }
`;

export const DELETE_WAREHOUSE_LV1 = gql`
  mutation WarehouseSubLevel1Delete(
    $uniqueInput: WarehouseSubLevel1WhereUniqueInput!
  ) {
    warehouseSubLevel1Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_WAREHOUSE_LV2 = gql`
  mutation WarehouseSubLevel2Create(
    $createInput: WarehouseSubLevel2CreateInput!
  ) {
    warehouseSubLevel2Create(createInput: $createInput) {
      id
      document_id
      name
    }
  }
`;

export const UPDATE_WAREHOUSE_LV2 = gql`
  mutation WarehouseSubLevel2Update(
    $uniqueInput: WarehouseWhereUniqueInput!
    $updateInput: WarehouseSubLevel2UpdateInput!
  ) {
    warehouseSubLevel2Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      document_id
      name
    }
  }
`;
export const DELETE_WAREHOUSE_LV2 = gql`
  mutation WarehouseSubLevel2Delete(
    $uniqueInput: WarehouseSubLevel2WhereUniqueInput!
  ) {
    warehouseSubLevel2Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_WAREHOUSE_LV3 = gql`
  mutation WarehouseSubLevel3Create(
    $createInput: WarehouseSubLevel3CreateInput!
  ) {
    warehouseSubLevel3Create(createInput: $createInput) {
      id
      document_id
      name
    }
  }
`;

export const UPDATE_WAREHOUSE_LV3 = gql`
  mutation WarehouseSubLevel3Update(
    $uniqueInput: WarehouseSubLevel3WhereUniqueInput!
    $updateInput: WarehouseSubLevel3UpdateInput!
  ) {
    warehouseSubLevel3Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      document_id
      name
    }
  }
`;

export const DELETE_WAREHOUSE_LV3 = gql`
  mutation WarehouseSubLevel3Delete(
    $uniqueInput: WarehouseSubLevel3WhereUniqueInput!
  ) {
    warehouseSubLevel3Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_BIN_LOCATION = gql`
  mutation BinLocationCreate($createInput: BinLocationCreateInput!) {
    binLocationCreate(createInput: $createInput) {
      id
      document_id
      name
    }
  }
`;

export const UPDATE_BIN_LOCATION = gql`
  mutation BinLocationUpdate(
    $uniqueInput: BinLocationWhereUniqueInput!
    $updateInput: BinLocationUpdateInput!
  ) {
    binLocationUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      name
    }
  }
`;

export const DELETE_BIN_LOCATION = gql`
  mutation BinLocationDelete($uniqueInput: BinLocationWhereUniqueInput!) {
    binLocationDelete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_ITEM_GROUP_LV1 = gql`
  mutation ItemGroupSubLevel1Create(
    $createInput: ItemGroupSubLevel1CreateInput!
  ) {
    itemGroupSubLevel1Create(createInput: $createInput) {
      id
      name
      document_id
    }
  }
`;

export const UPDATE_ITEM_GROUP_LV1 = gql`
  mutation ItemGroupSubLevel1Update(
    $uniqueInput: ItemGroupSubLevel1WhereUniqueInput
    $updateInput: ItemGroupSubLevel1UpdateInput!
  ) {
    itemGroupSubLevel1Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      name
      document_id
    }
  }
`;

export const DELETE_ITEM_GROUP_LV1 = gql`
  mutation ItemGroupSubLevel1Delete(
    $uniqueInput: ItemGroupSubLevel1WhereUniqueInput!
  ) {
    itemGroupSubLevel1Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_ITEM_GROUP_LV2 = gql`
  mutation ItemGroupSubLevel2Create(
    $createInput: ItemGroupSubLevel2CreateInput!
  ) {
    itemGroupSubLevel2Create(createInput: $createInput) {
      id
      name
      document_id
      sub_level_1 {
        id
        name
        document_id
      }
    }
  }
`;

export const UPDATE_ITEM_GROUP_LV2 = gql`
  mutation ItemGroupSubLevel2Update(
    $uniqueInput: ItemGroupSubLevel2WhereUniqueInput
    $updateInput: ItemGroupSubLevel2UpdateInput!
  ) {
    itemGroupSubLevel2Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      name
      document_id
      sub_level_1 {
        id
        name
        document_id
      }
    }
  }
`;

export const DELETE_ITEM_GROUP_LV2 = gql`
  mutation ItemGroupSubLevel2Delete(
    $uniqueInput: ItemGroupSubLevel2WhereUniqueInput!
  ) {
    itemGroupSubLevel2Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_ITEM_GROUP_LV3 = gql`
  mutation ItemGroupSubLevel3Create(
    $createInput: ItemGroupSubLevel3CreateInput!
  ) {
    itemGroupSubLevel3Create(createInput: $createInput) {
      id
      name
      document_id
    }
  }
`;

export const UPDATE_ITEM_GROUP_LV3 = gql`
  mutation ItemGroupSubLevel3Update(
    $uniqueInput: ItemGroupSubLevel3WhereUniqueInput
    $updateInput: ItemGroupSubLevel3UpdateInput!
  ) {
    itemGroupSubLevel3Update(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      name
      document_id
    }
  }
`;

export const DELETE_ITEM_GROUP_LV3 = gql`
  mutation ItemGroupSubLevel3Delete(
    $uniqueInput: ItemGroupSubLevel3WhereUniqueInput!
  ) {
    itemGroupSubLevel3Delete(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const CREATE_PAYMENT_CHANNEL = gql`
  mutation CreatePaymentChannel(
    $createPaymentChannelInput: CreatePaymentChannelInput!
  ) {
    createPaymentChannel(
      createPaymentChannelInput: $createPaymentChannelInput
    ) {
      id
      payment_channel_document_id
      is_business_owned_account
      for_receive
      for_pay
      for_checking_account
      payment_receive_by
      cash_name
      bank_name
      bank_account_type
      bank_account_name
      bank_account_number
      bank_branch_name
      bank_branch_number
      e_wallet_type
      e_wallet_provider
      e_wallet_account_name
      e_wallet_account_number
      cheque_bank_name
      cheque_bank_account_number
      cheque_amount
      cheque_receive_date
      cheque_number
      cheque_branch_number
      advanced_payment_by
      is_remember_payment_method
      withholding_tax
      deduction_rate
      is_withholding_tax
      description
    }
  }
`;

export const UPDATE_PAYMENT_CHANNEL = gql`
  mutation UpdatePaymentChannel(
    $updatePaymentChannelInput: UpdatePaymentChannelInput!
  ) {
    updatePaymentChannel(
      updatePaymentChannelInput: $updatePaymentChannelInput
    ) {
      id
      payment_channel_document_id
      is_business_owned_account
      for_receive
      for_pay
      for_checking_account
      payment_receive_by
      cash_name
      bank_name
      bank_account_type
      bank_account_name
      bank_account_number
      bank_branch_name
      bank_branch_number
      e_wallet_type
      e_wallet_provider
      e_wallet_account_name
      e_wallet_account_number
      cheque_bank_name
      cheque_bank_account_number
      cheque_amount
      cheque_receive_date
      cheque_number
      cheque_branch_number
      advanced_payment_by
      is_remember_payment_method
      withholding_tax
      deduction_rate
      is_withholding_tax
      description
    }
  }
`;

export const REMOVE_PAYMENT_CHANNEL = gql`
  mutation RemovePaymentChannel($removePaymentChannelId: Int!) {
    removePaymentChannel(id: $removePaymentChannelId) {
      id
      payment_channel_document_id
      is_business_owned_account
      for_receive
      for_pay
      for_checking_account
      payment_receive_by
      cash_name
      bank_name
      bank_account_type
      bank_account_name
      bank_account_number
      bank_branch_name
      bank_branch_number
      e_wallet_type
      e_wallet_provider
      e_wallet_account_name
      e_wallet_account_number
      cheque_bank_name
      cheque_bank_account_number
      cheque_amount
      cheque_receive_date
      cheque_number
      cheque_branch_number
      advanced_payment_by
      is_remember_payment_method
      withholding_tax
      deduction_rate
      is_withholding_tax
      description
    }
  }
`;

export const CREATE_COMPANY_SETTING = gql`
  mutation CreateCompanySetting(
    $createCompanySettingInput: CreateCompanySettingInput!
  ) {
    createCompanySetting(
      createCompanySettingInput: $createCompanySettingInput
    ) {
      id
      company_document_id
      img_url
      last_updator_document_id
      created_date
      identity_no
      registered_capital
      contact_type_1
      contact_type_2
      company_name
      contact_name_1
      contact_name_2
      initial
      register_date
      is_registered_vat
      vat_registration_date
      contact_person_list {
        position
        initial
        name_1
        name_2
        name_3
        img_url
        remark
        contact_channel_list {
          contact_channel
          contact_info_1
          contact_info_2
        }
      }
      contact_channel_list {
        contact_channel
        contact_info_1
        contact_info_2
      }
      address_list {
        address_type
        default_address
        same_as_default_address
        address
        sub_district
        district
        province
        postal_code
        country
        address_contact_name
        address_contact_phone
      }
    }
  }
`;

export const UPDATE_COMPANY_SETTING = gql`
  mutation UpdateCompanySetting(
    $updateCompanySettingInput: UpdateCompanySettingInput!
  ) {
    updateCompanySetting(
      updateCompanySettingInput: $updateCompanySettingInput
    ) {
      id
      company_document_id
      img_url
      last_updator_document_id
      created_date
      identity_no
      registered_capital
      contact_type_1
      contact_type_2
      company_name
      contact_name_1
      contact_name_2
      initial
      register_date
      is_registered_vat
      vat_registration_date
      contact_person_list {
        position
        initial
        name_1
        name_2
        name_3
        img_url
        remark
        contact_channel_list {
          contact_channel
          contact_info_1
          contact_info_2
        }
      }
      contact_channel_list {
        contact_channel
        contact_info_1
        contact_info_2
      }
      address_list {
        address_type
        default_address
        same_as_default_address
        address
        sub_district
        district
        province
        postal_code
        country
        address_contact_name
        address_contact_phone
      }
    }
  }
`;

export const CREATE_REMARK_TEMPLATE = gql`
  mutation Mutation($createInput: RemarkTemplateCreateInput!) {
    remarkTemplateCreate(createInput: $createInput) {
      id
      name
      document_type
      remark_text
    }
  }
`;

export const UPDATE_REMARK_TEMPLATE = gql`
  mutation RemarkTemplateUpdate(
    $uniqueInput: RemarkTemplateUniqueInput!
    $updateInput: RemarkTemplateUpdateInput!
  ) {
    remarkTemplateUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      name
      document_type
      remark_text
    }
  }
`;

export const REMOVE_REMARK_TEMPLATE = gql`
  mutation RemarkTemplateDelete($uniqueInput: RemarkTemplateUniqueInput!) {
    remarkTemplateDelete(uniqueInput: $uniqueInput) {
      id
      name
      document_type
      remark_text
    }
  }
`;

export const CREATE_APPROVAL_TEMPLATE = gql`
  mutation ApprovalTemplateCreate($createInput: ApprovalTemplateCreateInput!) {
    approvalTemplateCreate(createInput: $createInput) {
      id
      step_number
      step_name
      action
      workflow_step_number
      workflow_document_type
      reviewer_document_id_list
      reviewer_list {
        id
        document_id
        first_name
        last_name
        img_url
        department
        position
        line_uid
        phone
        email
      }
      required_approval_number
      is_required_approve
    }
  }
`;

export const UPDATE_APPROVAL_TEMPLATE = gql`
  mutation ApprovalTemplateUpdate(
    $uniqueInput: ApprovalTemplateUniqueInput!
    $updateInput: ApprovalTemplateUpdateInput!
  ) {
    approvalTemplateUpdate(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      step_number
      step_name
      action
      workflow_step_number
      workflow_document_type
      reviewer_list {
        id
        document_id
        first_name
        last_name
        img_url
        department
        position
        line_uid
        phone
        email
      }
      required_approval_number
      is_required_approve
    }
  }
`;

export const DELETE_APPROVAL_TEMPLATE = gql`
  mutation ApprovalTemplateDelete($uniqueInput: ApprovalTemplateUniqueInput!) {
    approvalTemplateDelete(uniqueInput: $uniqueInput) {
      deleted_info {
        id
        step_number
        step_name
        action
        workflow_step_number
        workflow_document_type
        reviewer_document_id_list
        reviewer_list {
          id
          document_id
          first_name
          last_name
          email
        }
        required_approval_number
        is_required_approve
      }
    }
  }
`;

export const DELETE_APPROVAL_TEMPLATE_WITH_NEXT_STEP = gql`
  mutation ApprovalTemplateDeleteNextStep(
    $uniqueInput: ApprovalTemplateUniqueInput!
  ) {
    approvalTemplateDeleteNextStep(uniqueInput: $uniqueInput) {
      deleted_info {
        id
        step_number
        step_name
        action
        workflow_step_number
        workflow_document_type
        reviewer_document_id_list
        reviewer_list {
          id
          document_id
          first_name
          last_name
          email
        }
        required_approval_number
        is_required_approve
      }
    }
  }
`;

export const CREATE_VEHICLE = gql`
  mutation CreateVehicle($createVehicleInput: CreateVehicleInput!) {
    createVehicle(createVehicleInput: $createVehicleInput) {
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      created_date
      id
      license_plate
      vehicle_type
      status
      remark_status
      remark
      volume
      weight
    }
  }
`;

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($updateVehicleInput: UpdateVehicleInput!) {
    updateVehicle(updateVehicleInput: $updateVehicleInput) {
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      created_date
      id
      license_plate
      vehicle_type
      remark_status
      status
      remark
      volume
      weight
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation RemoveVehicle($removeVehicleId: Int!) {
    removeVehicle(id: $removeVehicleId) {
      id
      license_plate
    }
  }
`;

export const CREATE_WORK_CENTER = gql`
  mutation WorkCenterCreate($createInput: WorkCenterCreateInput!) {
    workCenterCreate(createInput: $createInput) {
      name
      plant
      description
      remark
    }
  }
`;

export const UPDATE_WORK_CENTER = gql`
  mutation WorkCenterUpdate(
    $uniqueInput: WorkCenterUniqueInput!
    $updateInput: WorkCenterUpdateInput!
  ) {
    workCenterUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      name
      plant
      description
      remark
    }
  }
`;

export const DELETE_WORK_CENTER = gql`
  mutation WorkCenterDelete($uniqueInput: WorkCenterUniqueInput!) {
    workCenterDelete(uniqueInput: $uniqueInput) {
      name
      plant
      description
      remark
    }
  }
`;

export const CLEAR_REPORT_QUEUE = gql`
  mutation ClearQueue {
    clearQueue
  }
`;
