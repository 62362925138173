import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../components/Custom/CustomizedButton";
import SettingService from "../services/Setting";
import { useSnackbar } from "notistack";

const SystemMaintenance = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const clearQueueHandler = async () => {
    try {
      await SettingService.clearReportQueue();
      enqueueSnackbar("ล้างคำขอในระบบสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ล้างคำขอในระบบไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  return (
    <>
      <Typography variant="h5">{t("maintenance")}</Typography>
      <Box sx={{ display: "flex", mt: 2 }}>
        <CustomizedButton
          title={"ล้างคำขอในระบบ"}
          variant="contained"
          size="medium"
          onClick={clearQueueHandler}
        />
      </Box>
    </>
  );
};

export default SystemMaintenance;
